<template>
  <div class="tw-flex tw-h-dvh">
    <div
      class="tw-w-[320px] tw-min-w-[320px] tw-h-full tw-p-4 tw-border-r tw-border-[#F0F0F0] tw-flex tw-flex-col"
    >
      <zem-card-title class="tw-mt-4">
        Модули и сущности
        <zem-link
          class="tw-text-xs tw-text-[#0DB2B2] tw-font-semibold"
          @click="resetAll"
          >Сбросить все</zem-link
        >
      </zem-card-title>
      <div class="tw-relative">
        <zem-input placeholder="Поиск" v-model="search" />
        <img
          :src="require('@/assets/icons/search.svg')"
          alt=""
          class="tw-size-3 tw-absolute tw-right-2 tw-top-2"
        />
      </div>
      <div class="tw-mt-5 tw-flex-grow tw-overflow-y-auto">
        <zem-collapse
          v-for="(section, sectionIndex) in permissionsTree"
          :key="sectionIndex"
          :arrow="false"
          :title="section.title"
          class="mt-0"
          class-header="tw-pl-0"
          class-body="tw-pb-2"
          not-border
          :ref="section.title"
          :is-opened-default="$route.query.s === section.section"
        >
          <ul class="tw-text-sm tw-space-y-2.5 tw-pl-[3px] tw-text-[#3E3E3E]">
            <li
              v-for="(container, containerIndex) in section['containers']"
              :key="containerIndex"
              @click="selectContainer(section.section, container.container)"
              class="tw-cursor-pointer hover:tw-text-[#0DB2B2] tw-transition-colors"
              :class="{
                'tw-text-[#0DB2B2] tw-font-semibold':
                  selectedContainer === container.container &&
                  selectedSection === section.section,
              }"
            >
              {{ container.title }}
            </li>
          </ul>
        </zem-collapse>
      </div>
    </div>
    <div class="accesses-container tw-flex-grow tw-flex tw-flex-col">
      <zem-card>
        <div>
          <template>
            <zem-card-title v-if="newElement" class="mb-0"
              >Создание роли</zem-card-title
            >
            <template v-else>
              <zem-card-title
                >Роль: {{ currentItem["display_name"] }}</zem-card-title
              >
              <div class="block-id">
                <span class="block-id__label"></span>
                ID {{ currentItem["number"] }}
              </div>
              <div v-if="!closeAcc([3])" class="block-id">
                <span class="block-id__label second"></span>
                ID {{ currentItem["id"] }}
              </div>
            </template>
          </template>
        </div>
        <div class="tw-flex tw-gap-x-2">
          <template v-if="!edit && !newElement">
            <img
              v-if="$can('edit', 'role-update')"
              :src="require('@/assets/icons/edit.svg')"
              alt=""
              class="block-id__edit"
              @click="edit = true"
            />
            <img
              :src="require('@/assets/icons/close.svg')"
              alt=""
              class="block-id__delete tw-ml-0"
              @click="closeRightSidebar"
            />
          </template>
          <template v-else>
            <img
              :src="require('@/assets/icons/close.svg')"
              alt=""
              class="block-id__delete tw-ml-0 mobile-d"
              @click="closeRightSidebar"
            />
            <zem-link class="mobile-d-none" @click="closeRightSidebar"
              >Отменить</zem-link
            >
            <zem-button
              :loading="loadingNewElement"
              @click="$emit(!edit ? 'on-create' : 'on-update')"
            >
              Сохранить
            </zem-button>
          </template>
        </div>
      </zem-card>

      <div class="zem-collapse-table rows mt-3 mb-3">
        <div
          class="zem-collapse-table__row"
          style="grid-template-columns: 1fr 2fr;"
        >
          <div class="zem-collapse-table__column title mt-1">
            Уникальный псевдоним
          </div>
          <div
            v-if="!edit && !newElement"
            class="zem-collapse-table__column text mt-1"
          >
            {{ currentItem["name"] }}
          </div>
          <ZemInput
            v-else
            :value="currentItem['name']"
            class="mt-0"
            type="text"
            @input="(e) => (currentItem['name'] = e)"
          />
          <div class="zem-collapse-table__column title mt-1">
            Отображаемое название
          </div>
          <div
            v-if="!edit && !newElement"
            class="zem-collapse-table__column text mt-1"
          >
            {{ currentItem["display_name"] }}
          </div>
          <ZemInput
            v-else
            :value="currentItem['display_name']"
            class="mt-0"
            type="text"
            @input="(e) => (currentItem['display_name'] = e)"
          />
          <div class="zem-collapse-table__column title mt-1">Описание</div>
          <div
            v-if="!edit && !newElement"
            class="zem-collapse-table__column text mt-1"
          >
            {{ currentItem["description"] }}
          </div>
          <ZemInput
            v-else
            :value="currentItem['description']"
            class="mt-0"
            type="text"
            @input="(e) => (currentItem['description'] = e)"
          />
        </div>
      </div>

      <!-- <ZemCheckbox v-model="isNewInterface" class="tw-mb-4"> Новый интерфейс </ZemCheckbox> -->

      <!-- <div
        v-if="!newElement && !$store.state.roles.isArchive && !isNewInterface"
        class="position-relative"
      >
        <preloader-wrapper :show="schemaData.length === 0" class="mt-4" />
        <template v-for="(schema, schemaIndex) in schemaData">
          <zem-collapse :key="schemaIndex" :title="schema.section">
            <template
              v-for="(container, containerIndex) in schema['containers']"
            >
              <zem-collapse
                v-if="schema['containers'].length > 0"
                :key="containerIndex"
                :arrow="false"
                :title="container.container"
                class="mt-0"
                not-border
              >
                <div class="zem-collapse-table rows">
                  <div
                    class="zem-collapse-table__row"
                    style="
                      grid-template-columns: repeat(1, 1fr);
                      gap: 10px;
                      margin-left: 19px;
                    "
                  >
                    <template
                      v-for="(permission,
                      permissionIndex) in container.permissions"
                    >
                      <ZemCheckbox
                        :key="permissionIndex"
                        v-model="permission['is_assigned']"
                        :disabled="
                          !edit || roleDisabled || currentItem.name === 'admin'
                        "
                      >
                        <p
                          v-tippy="{
                            delay: 200,
                            content: permission['description'],
                          }"
                        >
                          {{ permission["display_name"] }}
                        </p>
                      </ZemCheckbox>
                      <p class="text-normal text-muted">{{ permission['name'] }}</p>
                    </template>
                  </div>
                </div>
              </zem-collapse>
            </template>
          </zem-collapse>
        </template>
      </div> -->

      <div class="tw-mt-4 tw-flex-1">
        <preloader-wrapper
          v-if="isLoadingPermissions"
          show
          class="tw-h-auto tw-relative"
        />
        <template v-else>
          <div
            v-for="(data, dataIndex) in permissions"
            :key="data.id"
            class="tw-px-4 tw-py-3 tw-border tw-border-[#F0F0F0] tw-space-y-2"
            :class="{
              'tw-rounded-t': dataIndex === 0,
              'tw-border-t-0': dataIndex > 0,
              'tw-rounded-b': dataIndex === permissions.length - 1,
            }"
          >
            <ZemCheckbox
              :disabled="!edit"
              v-model="data['assigned']"
              :class="{ 'tw-mb-4': data['schema'].length > 0 }"
            >
              <span
                v-tippy="{
                  content: `${data.description}<br>${data.name}`,
                  delay: [500, null],
                  allowHTML: true,
                  interactive: true,
                }"
                class="tw-text-sm tw-font-semibold"
              >
                {{ data.title }}
              </span>
            </ZemCheckbox>
            <div
              v-for="(schema, schemaIndex) in data['schema']"
              :key="schema.id"
            >
              <!-- Условия -->
              <zem-collapse
                v-if="schema['conditions'].length > 0"
                :arrow="false"
                :title="schema.title"
                class="mt-0"
                class-header="tw-p-0 tw-pl-1 tw-h-auto tw-text-xs"
                class-title="tw-p-0"
                class-body="tw-px-0 tw-pb-0"
                not-border
              >
                <div>
                  <!-- Блоки -->
                  <template v-if="schemaIndex === 0">
                    <zem-collapse
                      v-for="(condition, conditionIndex) in schema[
                        'conditions'
                      ]"
                      :key="condition.id"
                      :title="`Группа ${conditionIndex + 1}`"
                      not-border
                      :arrow="false"
                      class="tw-mt-2"
                      class-header="tw-h-auto tw-text-xs"
                      class-body="tw-px-0 tw-pb-0"
                    >
                      <template v-slot:header>
                        <zem-dropdown-menu v-if="edit">
                          <template v-slot:options>
                            <p
                              class="tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                              @click="
                                deleteGroup(
                                  dataIndex,
                                  schemaIndex,
                                  conditionIndex
                                )
                              "
                            >
                              Удалить группу
                            </p>
                          </template>
                        </zem-dropdown-menu>
                      </template>
                      <div
                        class="zem-collapse-table__row"
                        :style="{
                          gridTemplateColumns: edit
                            ? '1fr 1fr 20px'
                            : '1fr 1fr',
                          gap: '10px',
                          marginLeft: '25px',
                          alignItems: !edit ? 'start' : 'center',
                        }"
                      >
                        <template
                          v-for="(value, valueIndex) in condition.value"
                        >
                          <ZemDropdownList
                            :key="valueIndex"
                            :disabled="!edit"
                            :options="
                              condition['allowed_lists']
                                .filter(
                                  (item) =>
                                    !condition.value
                                      .map((value) => value.type.value)
                                      .includes(item.key)
                                )
                                .map((item) => ({
                                  title: item.title,
                                  value: item.key,
                                  multiple: item.multiple,
                                }))
                            "
                            v-model="value['type']"
                            class="tw-mt-0"
                          />
                          <ZemMultiSelect
                            v-if="
                              condition['allowed_lists'].find(
                                (item) => item.key === value['type'].value
                              ).multiple
                            "
                            :disabled="!edit"
                            :key="valueIndex + 100000"
                            :options="
                              lists
                                ? lists[
                                    condition['allowed_lists'].find(
                                      (item) => item.key === value['type'].value
                                    ).from
                                  ].options
                                : []
                            "
                            v-model="value['values']"
                          />
                          <ZemDropdownList
                            v-else
                            :disabled="!edit"
                            :options="
                              lists
                                ? lists[
                                    condition['allowed_lists'].find(
                                      (item) => item.key === value['type'].value
                                    ).from
                                  ].options
                                : []
                            "
                            v-model="value['values']"
                            class="tw-mt-0"
                          />
                          <button
                            v-if="edit"
                            class="tw-size-3 tw-fill-[#FF4D4F] tw-flex tw-items-center"
                            @click="
                              removeCondition(
                                dataIndex,
                                schemaIndex,
                                conditionIndex,
                                valueIndex
                              )
                            "
                          >
                            <iXMark />
                          </button>
                        </template>
                        <button
                          v-if="
                            edit &&
                            condition['allowed_lists'].filter(
                              (item) =>
                                !condition.value
                                  .map((value) => value.type.value)
                                  .includes(item.key)
                            ).length > 0
                          "
                          class="tw-col-span-3 tw-fill-[#0DB2B2] tw-flex tw-items-center tw-gap-1"
                          :class="{ 'tw-mt-1': condition.value.length === 0 }"
                          @click="
                            addCondition(dataIndex, schemaIndex, conditionIndex)
                          "
                        >
                          <iPlus class="tw-size-3" /><span
                            class="tw-text-xs tw-font-semibold tw-text-[#0DB2B2]"
                            >Условие</span
                          >
                        </button>
                      </div>
                    </zem-collapse>
                    <button
                      v-if="edit"
                      class="tw-col-span-3 tw-fill-[#0DB2B2] tw-flex tw-items-center tw-gap-[6px] tw-mt-2 tw-ml-[9px]"
                      @click="addGroup(dataIndex, schemaIndex)"
                    >
                      <iPlus class="tw-size-3" /><span
                        class="tw-text-xs tw-font-semibold tw-text-[#0DB2B2]"
                        >Группа</span
                      >
                    </button>
                  </template>
                  <template v-else>
                    <div
                      class="zem-collapse-table__row"
                      :style="{
                        gridTemplateColumns: edit ? '1fr 1fr 20px' : '1fr 1fr',
                        gap: '10px',
                        marginLeft: '18px',
                        alignItems: !edit ? 'start' : 'center',
                      }"
                    >
                      <template
                        v-for="(condition, conditionIndex) in schema[
                          'conditions'
                        ]"
                      >
                        <template
                          v-for="(value, valueIndex) in condition.value"
                        >
                          <ZemDropdownList
                            :key="valueIndex"
                            :disabled="!edit"
                            :options="
                              condition['allowed_lists'].map((item) => ({
                                title: item.title,
                                value: item.key,
                                multiple: item.multiple,
                              }))
                            "
                            v-model="value['type']"
                            class="tw-mt-0"
                          />
                          <ZemMultiSelect
                            v-if="
                              condition['allowed_lists'].find(
                                (item) => item.key === value['type'].value
                              ).multiple
                            "
                            :disabled="!edit"
                            :key="valueIndex + 100000"
                            :options="
                              lists
                                ? lists[
                                    condition['allowed_lists'].find(
                                      (item) => item.key === value['type'].value
                                    ).from
                                  ].options
                                : []
                            "
                            v-model="value['values']"
                          />
                          <ZemDropdownList
                            v-else
                            :disabled="!edit"
                            :options="
                              lists
                                ? lists[
                                    condition['allowed_lists'].find(
                                      (item) => item.key === value['type'].value
                                    ).from
                                  ].options
                                : []
                            "
                            v-model="value['values']"
                            class="tw-mt-0"
                          />
                          <button
                            v-if="edit"
                            class="tw-size-3 tw-fill-[#FF4D4F] tw-flex tw-items-center"
                            @click="
                              removeCondition(
                                dataIndex,
                                schemaIndex,
                                conditionIndex,
                                valueIndex
                              )
                            "
                          >
                            <iXMark />
                          </button>
                        </template>
                        <button
                          v-if="
                            edit &&
                            condition['allowed_lists'].filter(
                              (item) =>
                                !condition.value
                                  .map((value) => value.type.value)
                                  .includes(item.key)
                            ).length > 0
                          "
                          class="tw-col-span-3 tw-fill-[#0DB2B2] tw-flex tw-items-center tw-gap-1"
                          :class="{ 'tw-mt-1': condition.value.length === 0 }"
                          @click="
                            addCondition(dataIndex, schemaIndex, conditionIndex)
                          "
                        >
                          <iPlus class="tw-size-3" /><span
                            class="tw-text-xs tw-font-semibold tw-text-[#0DB2B2]"
                            >Запрет</span
                          >
                        </button>
                      </template>
                    </div>
                  </template>
                </div>
              </zem-collapse>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ZemCard from "@/components/ui/ZemCard";
import ZemCardTitle from "@/components/ui/ZemCardTitle";
import ZemCollapse from "@/components/ui/ZemCollapse";
import ZemInput from "@/components/ui/ZemInput";
import ZemDropdownList from "@/components/ui/ZemDropdownList";
import ZemSelect from "@/components/ui/Select.vue";
import ZemMultiSelect from "@/components/ui/ZemMultiSelect.vue";
import { eventBus } from "@/main";
import ZemLink from "@/components/ui/ZemLink.vue";
import ZemButton from "@/components/ui/ZemButton.vue";
import ZemRadioButton from "@/components/ui/ZemRadioButton.vue";
import ZemCheckbox from "@/components/ui/Checkbox.vue";
import { closeAcc } from "@/assets/scripts/scripts";
import ZemPreloader from "@/components/ui/ZemPreloader.vue";
import PreloaderWrapper from "@/components/PreloaderWrapper.vue";
import iXMark from "@/components/icons/iXMark.vue";
import iPlus from "@/components/icons/iPlus.vue";
import iCheck from "@/components/icons/iCheck.vue";
import ZemDropdownMenu from "@/components/ui/ZemDropdownMenu.vue";

import RolesService from "@/services/roles.service";

export default {
  components: {
    PreloaderWrapper,
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    iXMark,
    iPlus,
    iCheck,
    ZemDropdownMenu,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    newElement: {
      type: Boolean,
      default: false,
    },
    loadingNewElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      newElementTitle: "",
      data: null,
      isNewInterface: true,
      testElements: [
        {
          title: "Название роли",
          value: "Снабженец",
        },
        {
          title: "Комментарий",
          value: "Справочник + продукты",
        },
      ],
      isNewGroup: false,
      search: "",
      titleNewGroup: "",
      loading: false,
      roleDisabled: true,

      // new data
      permissionsTree: [],
      permissions: [],
      lists: null,
      selectedSection: null,
      selectedContainer: null,
      isLoadingPermissions: false,
    };
  },
  watch: {
    currentItem() {
      this.roleDisabled = this.currentItem.name === this.$store.state.user.role;
    },
  },
  mounted() {
    this.getPermissionsTree();
    const { s, c } = this.$route.query;
    if (s && c) {
      this.selectContainer(s, c);
    }
  },
  methods: {
    closeAcc,
    closeRightSidebar() {
      this.edit = false;
      this.newElement = false;
      eventBus.$emit("new-state", false);
      this.$store.commit("sidebars/changeRightSidebar", false);
    },
    resetAll() {
      this.search = "";
      this.selectedModule = null;
      this.selectedContainer = null;
      this.permissions = [];
      this.$router.push({
        name: "roles-id",
        params: { id: this.currentItem.id },
      });
    },
    deleteGroup(dataIndex, schemaIndex, conditionIndex) {
      this.permissions[dataIndex].schema[schemaIndex].conditions.splice(
        conditionIndex,
        1
      );
    },
    removeCondition(dataIndex, schemaIndex, conditionIndex, valueIndex) {
      this.permissions[dataIndex].schema[schemaIndex].conditions[
        conditionIndex
      ].value.splice(valueIndex, 1);
    },
    addCondition(dataIndex, schemaIndex, conditionIndex) {
      const condition = this.permissions[dataIndex].schema[schemaIndex]
        .conditions[conditionIndex];
      const value = this.permissions[dataIndex].schema[schemaIndex].conditions[
        conditionIndex
      ].value;
      this.permissions[dataIndex].schema[schemaIndex].conditions[
        conditionIndex
      ].value.push({
        type: condition.allowed_lists
          .filter(
            (item) => !value.map((value) => value.type.value).includes(item.key)
          )
          .map((item) => ({
            title: item.title,
            value: item.key,
          }))[0],
        values: [],
      });
    },
    addGroup(dataIndex, schemaIndex) {
      this.permissions[dataIndex].schema[schemaIndex].conditions.push({
        type: "groups",
        title: "",
        value: [],
        allowed_lists: [
          {
            title: "Тип мероприятий",
            key: "event_types", // Ключ для сохранения в базу
            from: "event_types", // Значение - название списка берётся из custom.lists.event_types
            multiple: true, // Множественное или нет
          },
          {
            title: "Кто создал",
            key: "created_by",
            from: "users",
            multiple: false,
          },
          {
            title: "Участник",
            key: "participant",
            from: "humans",
            multiple: true,
          },
          {
            title: "Ответственный",
            key: "responsible_by",
            from: "users",
            multiple: true,
          },
        ],
      });
      this.isNewGroup = false;
      this.titleNewGroup = "";
    },
    selectContainer(section, container) {
      this.selectedSection = section;
      this.selectedContainer = container;
      const isCurrentPath =
        this.$route.query.s === section && this.$route.query.c === container;
      if (!isCurrentPath)
        this.$router.push({
          query: {
            s: section,
            c: container,
          },
        });
      this.selectModule();
    },
    selectModule(roleName = null) {
      if (
        this.selectedSection &&
        this.selectedContainer &&
        this.currentItem.name
      ) {
        this.isLoadingPermissions = true;
        RolesService.getPermissions(
          this.selectedSection,
          this.selectedContainer,
          roleName ? roleName : this.currentItem.name
        )
          .then((res) => {
            this.permissions = res.data.data.map((item) => {
              return {
                ...item,
                schema: item.schema.map((schema) => {
                  return {
                    ...schema,
                    conditions: schema.conditions.map((condition) => {
                      let valuesData = [];
                      if (Array.isArray(condition.value)) {
                        condition.value.forEach((value) => {
                          Object.keys(value).map((key) => {
                            valuesData.push(value[key]);
                          });
                        });
                      } else {
                        valuesData = condition.value.columns;
                      }
                      return {
                        ...condition,
                        value: valuesData,
                      };
                    }),
                  };
                }),
              };
            });
            this.lists = res.data.custom.lists;
          })
          .finally(() => {
            this.isLoadingPermissions = false;
          });
      }
    },

    // new methods
    getPermissionsTree() {
      RolesService.getPermissionsTree().then((res) => {
        this.permissionsTree = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.accesses-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      img:not(:first-child) {
        margin-left: 8px;
      }

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.accesses-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__info-container:not(:first-child) {
    margin-top: 6px;
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
